<template>
  <cp-general-modal
    ref="createModal"
    title="Add Investor"
    title-icon="ion ion-ios-person-add"
    :ok-disabled="disabled"
    @shown="setInitial"
    @onOk="saveChanges"
  >
    <cp-select
      v-model="model.investorType"
      label="Investor Type"
      name="investorType"
      :options="optionsList.investorsTypesList"
    />

    <div v-show="isIndividual">
      <div class="d-flex justify-content-between">
        <div class="w-100 mr-3">
          <cp-input
            v-model="model.firstName"
            name="firstName"
            label="First Name"
          />
        </div>

        <div class="w-100">
          <cp-input
            v-model="model.middleName"
            name="middleName"
            label="Middle Name"
          />
        </div>
      </div>

      <cp-input
        v-model="model.lastName"
        name="lastName"
        label="Last Name"
      />
    </div>
    <div v-show="!isIndividual">
      <cp-input
        v-model="model.entityName"
        name="entityName"
        label="Entity legal name"
      />

      <cp-input
        v-model="model.entityDba"
        name="entityDba"
        label="Entity DBA"
      />
    </div>

    <cp-input
      v-model="model.email"
      name="email"
      :label="isIndividual ? 'Identifier Email and User Credentials' : 'Identifier Email'"
      validate="required|email"
    />

    <cp-select
      v-model="model.countryCode"
      name="countryCode"
      placeholder
      label="Country"
      validate="required"
      :options="countriesList"
    />

    <cp-select
      v-if="isUsa"
      v-model="model.state"
      placeholder
      name="state"
      label="State"
      :validate="isUsa ? 'required' : null"
      :options="stateList"
    />

    <cp-select
      v-show="!isIndividual"
      v-model="model.entityType"
      name="entityType"
      placeholder
      label="Entity type"
      :options="optionsList.entityTypesList"
    />
  </cp-general-modal>
</template>

<script>
import { mapActions } from 'vuex';
import states from '~/utilities/us-states-list';
import routeNames from '~/utilities/routes';
import CpGeneralModal from '~/components/common/modals-components/general-modal';
import CpInput from '~/components/common/standalone-components/inputs/cp-input';
import CpSelect from '~/components/common/standalone-components/inputs/cp-select';
import { countriesList, optionsList } from '@/pages/securitize-id/components/options';

export default {
  name: 'CreateEntity',
  components: {
    CpGeneralModal,
    CpInput,
    CpSelect,
  },
  props: {
    countryCodesList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      countriesList,
      optionsList,
      states,
      disabled: false,
      model: {
        investorType: 'individual',
      },
    };
  },
  computed: {
    stateList() {
      return Object.keys(this.states)
        .map(key => ({
          value: key,
          text: this.states[key],
        }));
    },
    isIndividual() {
      return ['individual', 'hidden-individual'].includes(this.model.investorType);
    },
    isUsa() {
      return this.model.countryCode === 'US';
    },
  },
  methods: {
    ...mapActions('securitizeId', ['createSecuritizeIdInvestor']),
    show() {
      this.$refs.createModal.show();
    },
    setInitial() {
      this.model = {
        investorType: 'individual',
      };
    },
    saveChanges() {
      this.disabled = true;

      if (!this.isUsa) delete this.model.state;

      if (this.model.investorType === 'hidden-individual') {
        this.model.investorType = 'individual';
        this.model.isHidden = true;
      }

      Object.keys(this.model).forEach((key) => {
        if (!this.model[key]) delete this.model[key];
      });

      this.createSecuritizeIdInvestor(this.model)
        .then(({ data }) => {
          this.$refs.createModal.hide();
          this.$router.push(routeNames.securitizeIdInvestor(data.investorId));
        })
        .finally(() => { this.disabled = false; });
    },
  },
};
</script>
